import React from "react";

import Image from "react-bootstrap/Image";
import Profile from "./Profile";

function IntroAnimation(props) {
  var [videoClass, setVideoClass] = React.useState("intro-video");
  var [containerClass, setContainerClass] = React.useState(
    "intro-animation-container"
  );

  function videoFinished() {
    setVideoClass("intro-video fade-out");
    const timer = setTimeout(() => {
      setContainerClass("intro-animation-container fade-out");
    }, 500);
    const timer2 = setTimeout(() => {
      props.setVisible(0);
    }, 1500);
    return () => {clearTimeout(timer); clearTimeout(timer2);}
  }

  return props.visible ? (
    <div className={containerClass}>
      <video
        className={videoClass}
        autoPlay
        muted
        playsInline
        src={`/media/intro-${props.device}.mp4`}
        type="video/mp4"
        onEnded={() => videoFinished()}
      ></video>
    </div>
  ) : (
    <></>
  );
}

export default IntroAnimation;
