import React from "react";

import Image, { propTypes } from "react-bootstrap/Image";

function Logbook(props) {
  var [highlighted, setHighlighted] = React.useState(0);
  var [tutorialStep, setTutorialStep] = React.useState(0);

  React.useEffect(() => {
    const step = localStorage.getItem("tutorial_step");
    if (step) {
      setTutorialStep(step);
    }
  }, [props.visible]);

  return props.visible ? (
    <div className="logbook-wrapper">
      <div className="logbook-container">
        <div className="logbook-header">
          <div>
            {props.homepage ? (
              <b className="black-bold">Retro Console Logbook</b>
            ) : (
              <b className="black-bold">My Logbook</b>
            )}
          </div>
          <Image
            className="x-icon"
            fluid
            src={`/media/x-icon.svg`}
            onClick={() => props.setVisible(0)}
          />
        </div>
        <div className="logbook-entries">
          {tutorialStep <= 1 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                Your logbook is empty. Go through the different steps and it
                will fill up!
              </div>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 1 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>1 - Looking for the fault</b>
              </div>
              <ul className="logbook-ul">
                <li>The device turned on ok</li>
                <li>The screen works fine</li>
                <li>The game wouldn’t start</li>
                <li>The start button moved but was broken</li>
                <li>There was not music, even after pressing the volume button</li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 3 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>2 - Removing the batteries</b>
              </div>
              <ul className="logbook-ul">
                <li>
                  The battery cover was taken off with a screwdriver by removing
                  the screws
                </li>
                <li>The 3x AA batteries were taken out</li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 6 ? (
            <>
              <div className="logbook-entry">
                <div className="logbook-title">
                  <b>3 - Taking it apart</b>
                </div>
                <ul className="logbook-ul">
                  <li>
                    The four screws on the back of the casing were removed
                  </li>
                  <li>The two parts were carefully unfolded</li>
                  <li>The 2 screws on the circuit board were removed</li>
                  <li>The speaker was removed from the front casing</li>
                  <li>The button membrane was removed</li>
                  <li>All the parts were laid out</li>
                </ul>
              </div>
              <Image
                fluid
                src="/media/exploded.png"
                className="exploded-image"
              />
            </>
          ) : (
            <></>
          )}
          {tutorialStep > 7 ? (
            <>
              <div className="logbook-entry">
                <div className="logbook-title">
                  <b>4 - Membrane buttons</b>
                </div>
                <ul className="logbook-ul">
                  <li>
                    The retro console uses buttons to control electricity going to
                    components
                    <Image
                      fluid
                      src="/media/step7/hiw-2.gif"
                      className="exploded-image"
                    />
                  </li>
                  <li>
                    The retro console uses membrane buttons.
                  </li>
                  <li>These consist of a rubber piece with a black conducting circle that gets pressed down onto the console's main circuit board - making the connection between two button contacts.
                  <Image
                      fluid
                      src="/media/step7/hiw-3.gif"
                      className="exploded-image"
                    />
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <></>
          )}
          {tutorialStep > 12 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>5 - Diagnosing the electronics</b>
              </div>
              <ul className="logbook-ul">
                <li>
                  The start button contact that lined up with the button on the
                  front casing was found
                </li>
                <li>The start button contact looked fine</li>
                <li>The button membrane's black pad was blocked by fluff.</li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 14 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>6 - Repairing the membrane</b>
              </div>
              <ul className="logbook-ul">
                <li>
                  The fluff was removed and membrane cleaned. Now when I press
                  the switch, the electricity can flow.
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 14.3 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>7 - Repairing the speaker</b>
              </div>
              <ul className="logbook-ul">
                <li>
                  The speaker cable was broken, preventing electricity from flowing.
                </li>
                <li>
                  The ends of the speaker cable were twisted round each other and taped, to stop the exposed ends touching anything else.
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 15 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>8 - Reassembling the gadget</b>
              </div>
              <ul className="logbook-ul">
                <li>
                  The reverse of the steps in Step 3 were followed to put the
                  gadget back together
                </li>
                <li>
                  The batteries were put back in, making sure to line up the
                  battery plus with the gadget minus sign.
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {tutorialStep > 16 ? (
            <div className="logbook-entry">
              <div className="logbook-title">
                <b>9 - Testing the gadget</b>
              </div>
              <ul className="logbook-ul">
                <li>
                  The gadget was turned on and the speaker was working.
                </li>
                <li>
                  The start button was working, a game could be started.
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Logbook;
