import React from "react";

import Image from "react-bootstrap/Image";
import Profile from "./Profile";

function Navbar(props) {
  var [showProfile, setShowProfile] = React.useState(0);
  var [avatar, setAvatar] = React.useState(0);

  const goBack = () => {
    if (props.back) {
      window.location.href = props.back;
    }
  };

  React.useEffect(() => {
    const storedAvatar = localStorage.getItem("avatar");
    if (!storedAvatar) {
      setShowProfile(1);
    } else {
      setAvatar(storedAvatar);
    }
  }, []);

  function updateDetails(newAvatar, newName) {
    setAvatar(newAvatar);
  }

  return (
    <div className="navbar">
      <Profile
        visible={showProfile}
        setVisible={setShowProfile}
        updateDetails={updateDetails}
      />
      <Image
        className="back-arrow"
        onClick={() => goBack()}
        fluid
        src="/media/back-arrow.svg"
      />
      {props.noAvatar ? (
        <div className="avatar"></div>
      ) : (
        <Image
          className="avatar"
          fluid
          src={`/media/avatars/${avatar}.png`}
          onClick={()=>setShowProfile(1)}
        />
      )}
    </div>
  );
}

export default Navbar;
