import "./App.css";

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Console from "./pages/console";
import Home from "./pages/homepage";
import ConsoleTutorial from "./pages/console-tutorial";
import Download from "./pages/download";

function App() {
  const breakpoint = 575;
  const breakpointDesktop = 1200;
  const [deviceLarge, setDeviceLarge] = React.useState(
    Number(window.innerWidth > breakpoint &&
      window.innerWidth < breakpointDesktop)
  );
  const [deviceDesktop, setDeviceDesktop] = React.useState(
    Number(window.innerWidth > breakpointDesktop)
  );
  //0 is phone, 1 is tablet

  React.useEffect(() => {
    const handleWindowResize = () => {
      setDeviceLarge(
        Number(
          window.innerWidth > breakpoint &&
            window.innerWidth < breakpointDesktop
        )
      );
    };
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  return (
    <div className="desktop-cont">
      <Router className="App">
        <Routes>
          <Route
            path="/console"
            element={<Console deviceLarge={deviceLarge} />}
          />
          <Route path="/tutorial" element={<ConsoleTutorial />} />
          <Route path="/" element={<Home />} />
          <Route path="/retro-console" element={<Download />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
