import React from "react";

import Image from "react-bootstrap/Image";

function Profile(props) {
  var [highlighted, setHighlighted] = React.useState(0);
  const nameInput = React.useRef();
  React.useEffect(() => {
    if (props.visible == 1) {
      const storedAvatar = localStorage.getItem("avatar");
      const storedName = localStorage.getItem("name");
      if (storedAvatar) {
        setHighlighted(storedAvatar);
        nameInput.current.value = storedName;
      } else {
        setHighlighted(0);
      }
    }
  }, [props.visible]);

  function saveChanges() {
    localStorage.setItem("avatar", highlighted);
    localStorage.setItem("name", nameInput.current.value);
    props.updateDetails(highlighted, nameInput.current.value);
    props.setVisible(0);
  }

  return props.visible ? (
    <div className="profile-wrapper">
      <div className="profile-container">
        <div className="profile-header">
          <div>
            <b className="black-bold">Profile</b>
          </div>
          <Image
            className="x-icon"
            fluid
            src={`/media/x-icon.svg`}
            onClick={() => props.setVisible(0)}
          />
        </div>
        <div className="profile-body">
          <div className="profile-title">Choose your avatar</div>
          <div className="profile-avatar-wrapper">
            {[...Array(10).keys()].map((i) => (
              <Image
                className="profile-avatar"
                style={
                  highlighted == i
                    ? { "box-shadow": "0px 0px 0px 6px var(--blue)" }
                    : {}
                }
                fluid
                src={`/media/avatars/${i}.png`}
                onClick={() => {
                  setHighlighted(i);
                }}
              />
            ))}
          </div>
          <div className="profile-title">What's your nickname?</div>
          <input className="profile-name" ref={nameInput}></input>
          <button className="profile-save" onClick={() => saveChanges()}>
            Save
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Profile;
