import React from "react";
import Navbar from "../components/Navbar";

import Image from "react-bootstrap/Image";
import Logbook from "../components/Logbook";

function HintOverlay(props) {
  return props.showHint ? (
    <div className="video-hint-overlay-bg">
      <div className="video-hint-overlay">
        <div className="video-hint-overlay-header">
          <div>
            <b className="black-bold">
              {props.squareVideo ? "Video " : ""}Hint
            </b>
          </div>
          <Image
            className="x-icon"
            fluid
            src={`/media/x-icon.svg`}
            onClick={() => props.setShowHint(0)}
          />
        </div>
        {props.squareVideo ? (
          // <Image
          //   className="video-hint"
          //   style={{ transform: "rotate(-90deg)" }}
          //   fluid
          //   src={`/media/step${props.tutorialStep}/video-hint.square-video`}
          // />
          <>
            {" "}
            <video
              className="video-hint"
              autoPlay
              muted
              loop
              playsInline
              src={`/media/step${props.tutorialStep}/video-hint.m4v`}
              type="video/mp4"
            ></video>
            {props.hint ? (
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                {props.hint}
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div>{props.hint}</div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}

function TutorialContent(props) {
  var [tutorialStep, setTutorialStep] = React.useState(1);
  var [step1Image, setStep1Image] = React.useState("main");
  var [step21Image, setStep21Image] = React.useState("main");
  var [step8Image, setStep8Image] = React.useState("main");
  var [step141Image, setStep141Image] = React.useState("main");
  var [step1Msg, setStep1Msg] = React.useState([]);
  var [step21Msg, setStep21Msg] = React.useState([]);
  var [step8Msg, setStep8Msg] = React.useState([]);
  var [step141Msg, setStep141Msg] = React.useState([]);
  var [showHint, setShowHint] = React.useState(0);
  var [noExpand, setNoExpand] = React.useState(0);
  var [scratchProp, setScratchProp] = React.useState("scratch-cover");
  var [show17Congrats, setShow17Congrats] = React.useState(0);

  React.useEffect(() => {
    const step = localStorage.getItem("tutorial_step");
    if (step) {
      setTutorialStep(step);
    } else {
      localStorage.setItem("tutorial_step", 1);
    }
  }, []);

  function moveStep(newStep) {
    setTutorialStep(newStep);
    localStorage.setItem("tutorial_step", newStep);
  }

  function handleStep1(clicked) {
    const options = {
      arrows: ["arrow keys", "are"],
      a: ["button A", "is"],
      b: ["button B", "is"],
      start: ["start button", "is"],
      reset: ["reset button", "is"],
      screen: ["screen", "is"],
      speaker: ["speaker", "is"],
    };
    setStep1Image(clicked);
    setStep1Msg(options[clicked]);
  }
  function handleStep21(clicked) {
    const options = {
      arrows: ["arrow keys", "are"],
      a: ["button A", "is"],
      b: ["button B", "is"],
      start: ["start button", "is"],
      reset: ["reset button", "is"],
      screen: ["screen", "is"],
      speaker: ["speaker", "is"],
    };
    setStep21Image(clicked);
    setStep21Msg(options[clicked]);
  }

  function handleStep8(clicked) {
    const options = {
      back: ["back casing", "that part is"],
      buttons: ["button caps", "those parts are"],
      membrane: ["button membranes", "that part is"],
      front: ["front casing", "that part is"],
      screen: ["screen", "that part is"],
      screws: ["screws", "those parts are"],
      speaker: ["speaker", "that part is"],
      switch: ["switch caps", "those parts are"],
    };
    setStep8Image(clicked);
    setStep8Msg(options[clicked]);
  }
  function handleStep141(clicked) {
    const options = {
      back: ["back casing", "that part is"],
      buttons: ["button caps", "those parts are"],
      membrane: ["button membranes", "that part is"],
      front: ["front casing", "that part is"],
      screen: ["screen", "that part is"],
      screws: ["screws", "those parts are"],
      speaker: ["speaker", "that part is"],
      switch: ["switch caps", "those parts are"],
      contacts: ["button contacts", "those parts are"],
    };
    setStep141Image(clicked);
    setStep141Msg(options[clicked]);
  }

  if (tutorialStep == 1) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Turn on the device and try to start a game, which button is broken?
          Tap the broken button!
        </div>
        <div className="console-wrapper">
          <Image
            className="console-image"
            fluid
            src={`/media/step1/${step1Image}.png`}
          />
          <div
            className="console-select"
            style={{
              width: "26%",
              height: "16%",
              top: "44%",
              left: "26%",
              transform: "rotate(-8deg)",
            }}
            onClick={() => handleStep1("arrows")}
          ></div>
          <div
            className="console-select"
            style={{
              width: "9%",
              height: "8%",
              top: "48%",
              left: "66%",
              transform: "rotate(-8deg)",
            }}
            onClick={() => handleStep1("a")}
          ></div>
          <div
            className="console-select"
            style={{
              width: "10%",
              height: "8%",
              top: "47%",
              left: "77%",
              transform: "rotate(-8deg)",
            }}
            onClick={() => handleStep1("b")}
          ></div>
          <div
            className="console-select"
            style={{
              width: "8%",
              height: "8%",
              top: "60%",
              left: "51%",
              transform: "rotate(-8deg)",
            }}
            onClick={() => handleStep1("reset")}
          ></div>
          <div
            className="console-select"
            style={{
              width: "8%",
              height: "8%",
              top: "59%",
              left: "61%",
              transform: "rotate(-8deg)",
            }}
            start
            onClick={() => {
              moveStep(2);
              props.newNotif();
            }}
          ></div>
        </div>
        {step1Msg.length == 0 ? (
          <></>
        ) : (
          <div className="select-message">
            Not quite, the <b>{step1Msg[0]}</b> {step1Msg[1]} not broken!
          </div>
        )}
      </div>
    );
  } else if (tutorialStep == 2) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Bingo, you got it! The <b>start button is not working.</b>
        </div>
        <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        <div className="console-wrapper">
          <Image
            className="console-image"
            fluid
            src={`/media/step1/start.png`}
          />
        </div>
        <button className="step-button-wrapper" onClick={() => moveStep(2.1)}>
          <div className="step-button-text">Next step</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
          />
        </button>
      </div>
    );
  } else if (tutorialStep == 2.1) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Something else is broken! Can you hear any music?{" "}
          <b>Click on the speaker</b>.
        </div>
        <div className="console-wrapper">
          <Image
            className="console-image"
            fluid
            src={`/media/step1/${step21Image}.png`}
          />
          <div
            className="console-select"
            style={{
              width: "13%",
              height: "10%",
              top: "74%",
              left: "29%",
              transform: "rotate(-8deg)",
            }}
            onClick={() => {
              moveStep(2.2);
              props.newNotif();
            }}
          ></div>
          <div
            className="console-select"
            style={{
              width: "61%",
              height: "35%",
              top: "7%",
              left: "18%",
              transform: "rotate(-5deg)",
            }}
            onClick={() => handleStep21("screen")}
          ></div>
        </div>
        {step21Msg.length == 0 ? (
          <></>
        ) : (
          <div className="select-message">
            Not quite, the <b>{step21Msg[0]}</b> {step21Msg[1]} not broken!
          </div>
        )}
      </div>
    );
  } else if (tutorialStep == 2.2) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Nice one, you got it! The <b>speaker isn't working either.</b>
        </div>
        <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        <div className="console-wrapper">
          <Image
            className="console-image"
            fluid
            src={`/media/step1/speaker.png`}
          />
        </div>
        <button className="step-button-wrapper" onClick={() => moveStep(3)}>
          <div className="step-button-text">Let's get fixing</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
          />
        </button>
      </div>
    );
  } else if (tutorialStep == 3) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          style={{ transform: "rotate(-90deg)" }}
          squareVideo={1}
          tutorialStep={tutorialStep}
          hint='Remember with screws, turning anti-clockwise loosens and turning clockwise tightens - "lefty loosy righty tighty!!"'
        />
        <div className="tutorial-title-2">
          Safety first! Let’s start by removing the batteries.
        </div>
        <Image className="step-image" fluid src={`/media/step3/main.png`} />
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">How do I remove them?</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(2)}
          />
          <div className="step-nav-number">1/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(4);
              props.newNotif();
            }}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 4) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Let’s take a close look at how batteries work!
        </div>
        <ol className="how-it-works">
          <Image
            className="how-it-works-title"
            fluid
            src={`/media/step4/how-it-works-title.png`}
          />
          <li>
            Our gadget uses batteries to power and control parts like the
            speaker.
          </li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step4/hiw-1.gif`}
          />
          <li>
            Electricity powers the processor which works out what all the parts
            need to do.
          </li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step4/hiw-2.gif`}
          />
          <li>
            The processor then sends power to the parts to make them do things.
          </li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step4/hiw-3.gif`}
          />
        </ol>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(3)}
          />
          <div className="step-nav-number">2/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(5);
            }}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 5) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={0}
          hint="Remove the four remaining screws on the back of the device. Remember 'lefty loosy righty tighty!!'. Put all the screws somewhere safe so they don't get lost!"
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-2">
          Now let’s try and fix it. Looks like we need to open the gadget up 🔧
        </div>
        <Image className="step-image" fluid src={`/media/step5/main.png`} />
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">I need help opening it!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(4)}
          />
          <div className="step-nav-number">3/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(6)}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 6) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={1}
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-2">
          Let’s see if you can take apart and group the components!
        </div>
        <Image className="step-image" fluid src={`/media/step6/main.png`} />
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">I need help taking it apart!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(5)}
          />
          <div className="step-nav-number">4/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(7)}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 7) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Before we fix the <b>start button</b>, let’s have a look at how
          buttons work 🔍
        </div>
        <ol className="how-it-works">
          <Image
            className="how-it-works-title"
            fluid
            src={`/media/step7/how-it-works-title.png`}
          />
          <li>
            When we want to control the electricity going to components, we use
            buttons.
          </li>
          <Image
            className="how-it-works-gif"
            style={{ "max-width": "450px" }}
            fluid
            src={`/media/step7/hiw-1.gif`}
          />
          <li>
            Buttons allow us to stop and start electricity flowing from one
            place to another.
          </li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step7/hiw-2.gif`}
          />
          <li>
            This gadget’s buttons only let electricity flow when pressed. The
            rubber membrane holds it up until you push it - connecting the wires
            allowing it to flow.
          </li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step7/hiw-3.gif`}
          />
        </ol>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(6)}
          />
          <div className="step-nav-number">5/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(8);
              props.newNotif();
            }}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 8) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Now that we know how buttons work, let’s fix the start button! First,
          tap on the <b>button contacts</b>?
        </div>
        <div className="step8-wrapper">
          <Image
            className="step8-image"
            fluid
            src={`/media/step8/${step8Image}.png`}
          />
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "59%",
              left: "28%",
              borderRadius: "10px",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep8("buttons")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "70%",
              left: "29%",
              borderRadius: "40px",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep8("speaker")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "8%",
              left: "7%",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep8("screws")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "8%",
              left: "60%",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep8("screws")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "18%",
              height: "13%",
              top: "61%",
              left: "10%",
              transform: "rotate(-1deg)",
            }}
            start
            onClick={() => {
              handleStep8("membrane");
            }}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "10%",
              height: "10%",
              top: "8%",
              left: "34%",
              transform: "rotate(-0deg)",
            }}
            onClick={() => handleStep8("switch")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "19%",
              height: "28%",
              top: "47%",
              left: "42%",
              transform: "rotate(-36deg)",
            }}
            onClick={() => moveStep(9)}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "17%",
              height: "28%",
              top: "37%",
              left: "60%",
              transform: "rotate(-36deg)",
            }}
            onClick={() => handleStep8("screen")}
          ></div>
        </div>
        {step8Msg.length == 0 ? (
          <></>
        ) : (
          <div className="select-message">
            Not quite, {step8Msg[1]} the <b>{step8Msg[0]}</b>! Try again!
          </div>
        )}
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(7)}
          />
          <div className="step-nav-number">6/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow-grey.svg`}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 9) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Great, you found the <b>button contacts</b>! Next let's see if they're
          causing the problem.
        </div>
        <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        <div className="step8-wrapper">
          <Image className="step8-image" fluid src={`/media/step9/main.png`} />
        </div>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(7);
              setStep8Image("main");
              setStep8Msg([]);
            }}
          />
          <div className="step-nav-number">6/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(10)}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 10) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={0}
          hint="Try and line up the start button on the front case with one of the silver contact circles."
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-2">
          Can you figure out which is the <b>start button contact</b>? Tap on
          it!
        </div>
        <div className="step10-wrapper">
          <Image
            className="step10-image"
            fluid
            src={`/media/step10/main.png`}
          />
          <div
            className="step10-select"
            style={{
              width: "9%",
              height: "9%",
              top: "58%",
              left: "31%",
              transform: "rotate(-25deg)",
              opacity: "0%",
            }}
            onClick={() => moveStep(11)}
          ></div>
        </div>
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">Give me a hint!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(9)}
          />
          <div className="step-nav-number">7/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow-grey.svg`}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 11) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Nice one! It looks like the <b>start button contact</b> is clean and
          working so lets keep looking!
        </div>
        <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        <div className="step10-wrapper">
          <Image
            className="step10-image"
            fluid
            src={`/media/step11/main.png`}
          />
        </div>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(9)}
          />
          <div className="step-nav-number">7/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(12)}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 12) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={0}
          hint="Compare the broken start button to the other working ones."
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-3">
          Here are the three parts that make up the buttons. Can you tap on the
          one that you think is broken one?
        </div>
        <div className="step10-wrapper">
          <Image
            className="step10-image"
            fluid
            src={`/media/step12/main.png`}
          />
          <div
            className="step10-select"
            style={{
              width: "45%",
              height: "12%",
              top: "82%",
              left: "7%",
              transform: "rotate(0deg)",
              opacity: "0%",
            }}
            onClick={() => {
              moveStep(13);
              props.newNotif();
            }}
          ></div>
        </div>
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">Give me a hint!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(11)}
          />
          <div className="step-nav-number">8/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow-grey.svg`}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 13) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Correct! There's something stuck in the membrane button.
        </div>
        <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        <div className="step10-wrapper">
          <Image
            className="step10-image"
            fluid
            src={`/media/step13/main.png`}
          />
        </div>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(11)}
          />
          <div className="step-nav-number">8/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(14)}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 14) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={1}
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-2">
          Carefully <b>remove the fluff</b>. Make sure the
          button membrane is clean and looks like the others!
        </div>
        <Image className="step-image" fluid src={`/media/step14/main.png`} />
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">Show me how!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(13)}
          />
          <div className="step-nav-number">8/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(14.1);
              props.newNotif();
            }}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 14.1) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Now we'll fix the speaker! Click on the speaker.
        </div>
        <div className="step8-wrapper">
          <Image
            className="step8-image"
            fluid
            src={`/media/step14.1/${step141Image}.png`}
          />
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "59%",
              left: "28%",
              borderRadius: "10px",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep141("buttons")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "70%",
              left: "29%",
              borderRadius: "40px",
              transform: "rotate(0deg)",
            }}
            onClick={() => moveStep(14.2)}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "8%",
              left: "7%",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep141("screws")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "12%",
              height: "12%",
              top: "8%",
              left: "60%",
              transform: "rotate(0deg)",
            }}
            onClick={() => handleStep141("screws")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "18%",
              height: "13%",
              top: "61%",
              left: "10%",
              transform: "rotate(-1deg)",
            }}
            start
            onClick={() => {
              handleStep141("membrane");
            }}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "10%",
              height: "10%",
              top: "8%",
              left: "34%",
              transform: "rotate(-0deg)",
            }}
            onClick={() => handleStep141("switch")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "19%",
              height: "28%",
              top: "47%",
              left: "42%",
              transform: "rotate(-36deg)"
            }}
            onClick={() => handleStep141("contacts")}
          ></div>
          <div
            className="step8-select"
            style={{
              width: "17%",
              height: "28%",
              top: "37%",
              left: "60%",
              transform: "rotate(-36deg)",
            }}
            onClick={() => handleStep141("screen")}
          ></div>
        </div>
        {step141Msg.length == 0 ? (
          <></>
        ) : (
          <div className="select-message">
            Not quite, {step141Msg[1]} the <b>{step141Msg[0]}</b>! Try again!
          </div>
        )}
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(14)}
          />
          <div className="step-nav-number">9/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow-grey.svg`}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 14.2) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-3">
          Correct! <b>Can you see what's wrong with it?</b>
        </div>
        <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        <div className="step10-wrapper">
          <Image
            className="step10-image"
            fluid
            src={`/media/step14.2/main.png`}
          />
        </div>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(14)}
          />
          <div className="step-nav-number">9/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(14.3)}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 14.3) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Now lets fix the speaker. The wire is broken preventing electricity
          from passing through it!
        </div>
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={1}
          tutorialStep={tutorialStep}
        />
        <ol className="how-it-works" style={{maxHeight:"45vh"}}>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step${tutorialStep}/main.png`}
          />
          <li>First bend the two ends into hooks.</li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step${tutorialStep}/main2.png`}
          />
          <li>
            Next bend the hooks around each other so the two ends are connected.
          </li>
          <Image
            className="how-it-works-gif"
            fluid
            src={`/media/step${tutorialStep}/main3.png`}
          />
          <li>
            Finally place a piece of tape over the top to make everything
            secure.
          </li>
          <button
            className="step-button-wrapper"
            onClick={() => {
              setShowHint(1);
            }}
          >
            <div className="step-button-text">Show me how!</div>
            <Image
              className="step-button-arrow"
              fluid
              src={`/media/announce-icon.svg`}
            />
          </button>
        </ol>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(14.1)}
          />
          <div className="step-nav-number">9/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {moveStep(15); props.newNotif();}}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 15) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          squareVideo={1}
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-3">
          Nice job! Now let’s put the whole thing back together. Open your
          logbook to remember how!
        </div>
        <Image className="step-image" fluid src={`/media/step15/main.png`} />
        <button
          className="step-button-wrapper"
          onClick={() => {
            setShowHint(1);
          }}
        >
          <div className="step-button-text">Show me how!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => moveStep(14)}
          />
          <div className="step-nav-number">10/10</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(16);
              props.newNotif();
            }}
          />
        </div>
      </div>
    );
  } else if (tutorialStep == 16) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-2">
          Switch on the gadget! Can you hear music?
        </div>
        <button
          className="step-button-wrapper"
          onClick={() => {
            moveStep(16.1);
            setNoExpand(0);
            props.newNotif();
          }}
        >
          <div className="step-button-text">Yes!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/heart-face.svg`}
          />
        </button>
        <div style={{ height: "32px" }}></div>
        <button
          className="button-expand-wrapper"
          onClick={() => {
            setNoExpand(1);
          }}
        >
          <div className="button-expand-header">
            <div className="step-button-text">Not yet</div>
            <Image
              className="step-button-arrow"
              fluid
              src={`/media/no-face.svg`}
            />
          </div>
          {noExpand ? (
            <>
              <div>
                Don’t give up! Check that the two exposed ends of the speaker wire are tightly wrapped together, and that the tape covers all of the exposed ends.
              </div>
              <a
                onClick={() => {
                  window.open("https://youtu.be/ovjZ2mZPxp0");
                }}
              >
                <b className="black-bold">
                  <u>Take me to the video</u>
                </b>
              </a>
            </>
          ) : (
            <></>
          )}
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(15);
              setNoExpand(0);
            }}
          />
          <div className="step-nav-number"></div>
          <div className="step-button-arrow"></div>
        </div>
      </div>
    );
  } else if (tutorialStep == 16.1) {
    return (
      <div className="tutorial-panel">
        <HintOverlay
          showHint={showHint}
          setShowHint={setShowHint}
          tutorialStep={tutorialStep}
        />
        <div className="tutorial-title-2">
          Nice one! Can you start a game by pressing the start button?
        </div>
        <button
          className="step-button-wrapper"
          onClick={() => {
            moveStep(17);
            setNoExpand(0);
            props.newNotif();
          }}
        >
          <div className="step-button-text">Yes!</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/heart-face.svg`}
          />
        </button>
        <div style={{ height: "32px" }}></div>
        <button
          className="button-expand-wrapper"
          onClick={() => {
            setNoExpand(1);
          }}
        >
          <div className="button-expand-header">
            <div className="step-button-text">Not yet</div>
            <Image
              className="step-button-arrow"
              fluid
              src={`/media/no-face.svg`}
            />
          </div>
          {noExpand ? (
            <>
              <div>
                Don’t give up! Check you've removed the fluff from the button contact and that all the button caps and membranes are correctly placed. You can also watch this video that takes you through all
                the steps.
              </div>
              <a
                onClick={() => {
                  window.open("https://youtu.be/ovjZ2mZPxp0");
                }}
              >
                <b className="black-bold">
                  <u>Take me to the video</u>
                </b>
              </a>
            </>
          ) : (
            <></>
          )}
        </button>
        <div className="step-nav-wrapper">
          <Image
            className="step-button-arrow"
            style={{ transform: "rotate(180deg)" }}
            fluid
            src={`/media/button-arrow.svg`}
            onClick={() => {
              moveStep(16.1);
              setNoExpand(0);
            }}
          />
          <div className="step-nav-number"></div>
          <div className="step-button-arrow"></div>
        </div>
      </div>
    );
  } else if (tutorialStep == 17) {
    return (
      <div className="tutorial-panel">
        <div className="tutorial-title-2">
          Amazing, you did it! You are officially a Team Repair console fixing
          expert! 🔧
        </div>
        {show17Congrats ? (
          <Image className="console-gif" fluid src={`/media/congrats.gif`} />
        ) : (
          <></>
        )}
        <div className="scratch-image-wrapper">
          <Image
            className={scratchProp}
            fluid
            src={`/media/step17/tap-cover.png`}
            onClick={() => {
              setScratchProp(scratchProp + " fade-out");
              setShow17Congrats(1);
              localStorage.setItem("console", 1);
            }}
          />
          <Image
            className="scratch-image"
            fluid
            src={`/media/step17/console-badge.png`}
          />
        </div>
        <button
          className="step-button-wrapper"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <div className="step-button-text">Back to homepage</div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/home-icon.svg`}
          />
        </button>
      </div>
    );
  }
}

function ConsoleTutorial(props) {
  var [showNotif, setShowNotif] = React.useState(0);
  var [notifVal, setNotifVal] = React.useState(0);
  var [logbookOpen, setLogbookOpen] = React.useState(0);

  const openLogbook = () => {
    setNotifVal(0);
    setShowNotif(0);
    setLogbookOpen(1);
  };

  function newNotif() {
    setNotifVal(notifVal + 1);
    setShowNotif(1);
  }

  return (
    <div className="main-container">
      <Navbar back="/console" />
      <TutorialContent newNotif={newNotif} />
      <Logbook visible={logbookOpen} setVisible={setLogbookOpen} />
      <div className="button-wrapper">
        {showNotif ? <div className="logbook-notif">{notifVal}</div> : <></>}
        <button className="logbook" onClick={() => openLogbook()}>
          {" "}
          View my logbook{" "}
        </button>
      </div>
    </div>
  );
}

export default ConsoleTutorial;
