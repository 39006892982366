import React from "react";
import Navbar from "../components/Navbar";

import Image from "react-bootstrap/Image";
import IntroAnimation from "../components/IntroAnimation";

function Console(props) {
  var [introVisible, setIntroVisible] = React.useState(1);

  return (
    <div className="main-container">
      <IntroAnimation device="console" visible={introVisible} setVisible={setIntroVisible}/>
      <Navbar back="/" />
      <Image
        className="p-header"
        fluid
        src={`/media/project-header${props.deviceLarge}.png`}
      />
      <Image
        style={{ "margin-top": "27px", width: "100%" }}
        fluid
        src={`/media/btn-lets-fix${props.deviceLarge}.png`}
        onClick={() => {
          window.location.href = "/tutorial";
        }}
      /> 
      <Image
        style={{ "margin-top": "24px", width: "100%" }}
        fluid
        src={`/media/btn-repair-walkthrough${props.deviceLarge}.png`}
        onClick={() => {
          window.open("https://youtu.be/ovjZ2mZPxp0");
        }}
      /> 
      <Image
        style={{ "margin-top": "24px", width: "100%", opacity:"50%" }}
        fluid
        src={`/media/btn-about-gadget${props.deviceLarge}.png`}
      />
      <Image
        style={{ "margin-top": "24px", width: "100%", opacity:"50%", marginBottom:"16px" }}
        fluid
        src={`/media/btn-repair-forum${props.deviceLarge}.png`}
      />
    </div>
  );
}

export default Console;
