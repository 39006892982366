import React from "react";

import Navbar from "../components/Navbar";
import Profile from "../components/Profile";

import Image from "react-bootstrap/Image";
import Logbook from "../components/Logbook";


function Home(props) {
  var [avatar, setAvatar] = React.useState(0);
  var [name, setName] = React.useState("");
  var [console, setConsole] = React.useState(0);
  var [step, setStep] = React.useState("");
  var [showProfile, setShowProfile] = React.useState(0);
  var [showLogbook, setShowLogbook] = React.useState(0);

  React.useEffect(() => {
    const storedAvatar = localStorage.getItem("avatar");
    const storedName = localStorage.getItem("name");
    const storedConsole = localStorage.getItem("console");
    const storedStep = localStorage.getItem("tutorial_step");
    if (!storedAvatar) {
      setShowProfile(1);
    } else {
      setAvatar(storedAvatar);
      setName(storedName);
      setConsole(storedConsole);
      setStep(storedStep);
    }
  }, []);

  function updateDetails(newAvatar, newName) {
    setAvatar(newAvatar);
    setName(newName);
  }

  return (
    <div className="main-container">
      <Logbook visible={showLogbook} setVisible={setShowLogbook} homepage={1}/>
      <Profile
        visible={showProfile}
        setVisible={setShowProfile}
        updateDetails={updateDetails}
      />
      <Image
        className="home-avatar"
        fluid
        src={`/media/avatars/${avatar}.png`}
        onClick={() => setShowProfile(1)}
      />
      <div className="home-name">
        Welcome back, <a style={{ color: "var(--red)" }}>{name}</a>
      </div>
      {!console ? (
        <button
          className="step-button-wrapper"
          style={{ "margin-bottom": "48px", marginTop:"0px" }}
          onClick={() => {
            window.location.href = "/console";
          }}
        >
          <div className="step-button-text">
            {step ? "Continue this" : "This"} month's repair
          </div>
          <Image
            className="step-button-arrow"
            fluid
            src={`/media/announce-icon.svg`}
          />
        </button>
      ) : (
        <></>
      )}
      <div className="home-title">Your previous repairs</div>
      <div className="home-badge-wrapper">
        {console ? (
          <Image className="home-badge" fluid src="/media/badge1.png" onClick={()=>{setShowLogbook(1)}}/>
        ) : (
          <></>
        )}
        <Image className="home-badge" fluid src="/media/badge2.png" />
        <Image className="home-badge" fluid src="/media/badge3.png" />
        <Image className="home-badge" fluid src="/media/badge4.png" />
      </div>
    </div>
  );
}

export default Home;
