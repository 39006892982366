import React from "react";
import Navbar from "../components/Navbar";

import Image from "react-bootstrap/Image";
import IntroAnimation from "../components/IntroAnimation";

function Download(props) {
  var [introVisible, setIntroVisible] = React.useState(1);

  return (
    <div style={{textAlign:"center", justifyContent:"center"}} className="main-container">
      <Image className="download-image" fluid src="/media/box_cutout_compressed.png" />
      <div style={{textAlign:"center", marginBottom:"24px"}}className="home-name">
        Hey! Thanks for taking part in our <b>testing program</b>!
      </div>
      <div style={{textAlign:"center", marginBottom:"48px"}}>
        To get started please add this page to your home screen.
      </div>

      <div>Not sure how?</div>
      <a href="https://www.howtogeek.com/667910/how-to-add-a-website-to-your-iphone-or-ipad-home-screen/">Help for Apple devices</a>
      <a href="https://www.wikihow.com/Set-a-Bookmark-Shortcut-in-Your-Home-Screen-on-Android">Help for Android devices</a>
    </div>
  );
}

export default Download;
